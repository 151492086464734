import React from "react";
import { vowels } from "./language";
import Vowel from "./Vowel";

export default ({ segment, decorate = true }) => {
  const letters = !decorate
    ? segment
    : [...segment].map(character => {
        return vowels.includes(character) && decorate ? (
          <Vowel key={`segment__vowel-${character}`} character={character} />
        ) : (
          <span key={`segment__character-${character}`} className="character character--normal">
            {character}
          </span>
        );
      });
  return <span className="segment">{letters}</span>;
};
