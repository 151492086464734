import { vowels } from "./language";
export const vowelAudio = vowels.reduce((o, vowel) => {
  o[vowel] = require(`./audio/vowels/${vowel}_64k.mp3`);
  return o;
}, {});


export const domPlay = evt => {
  const audioUrl = evt.currentTarget.dataset.audio;

  if (!audioUrl) {
    return;
  }

  const player = document.createElement("audio");
  player.src = audioUrl;
  player.play();
};