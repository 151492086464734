import { DEFINITION_ENDPOINT_URL } from "./environment";

export default class Definition {
  constructor(term) {
    this.term = term;
    this.endpoint = new URL(DEFINITION_ENDPOINT_URL);
    this.params = { query: term, action: "first" };
  }

  resolve = () =>
    fetch(this.url())
      .then(r => r.json())
      .then(({result}) => result);
  url() {
    let url = this.endpoint;
    Object.keys(this.params).forEach(key => url.searchParams.append(key, this.params[key]));

    return url.toString();
  }
}
