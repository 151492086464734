import React, { useState, useEffect } from "react";
import Segment from "./Segment";
import Definition from "./Definition";
import { domPlay as playDefinition } from "./audio";

export default ({ word, decorate = true, define = false }) => {
  const [definition, setDefinition] = useState();
  useEffect(() => {
    if (define && word.reduce((acc, curr) => (acc += curr.length), 0) >= 2) {
      new Definition(word).resolve().then(setDefinition);
    }
  }, [word, define]);

  return (
    <li
      tabIndex={definition && 0}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      data-audio={definition && definition.audio}
      onKeyPress={definition ? playDefinition : null}
      onClick={definition ? playDefinition : null}
      className={definition ? "word with-audio" : "word"}
    >
      {word.map((segment, index) => (
        <Segment key={`word__segment-${segment}-${index}`} segment={segment} decorate={decorate} />
      ))}
    </li>
  );
};
