import React, { useEffect, useState } from "react";
import { vowels, macrons } from "./language";
import Button from "@material/react-button";
import Word from "./Word";
import PhraseSpeakalong from "./PhraseSpeakalong";
import TextareaAutosize from "react-textarea-autosize";

import "./App.scss";

const CHARACTERS_SPOKEN_PER_SECOND = 16.45;
const WordDivider = () => <span className="word-divider">&middot;</span>;

const simplifyWotdDescription = node => {
  const parent = document.createElement("div");
  parent.innerHTML = node.textContent;

  parent.querySelectorAll("p").forEach(n => n.parentNode.removeChild(n));
  return parent.textContent;
};

function App() {
  var urlParams = new URLSearchParams(window.location.search);

  const initialPhrase = "Te wiki o te Māori";
  const urlPhrase = urlParams.has("phrase") ? urlParams.get("phrase") : null;
  const [phrase, setPhrase] = useState(initialPhrase);
  const [words, setWords] = useState([]);
  const [wotd, setWotd] = useState();
  const [slowdown, setSlowdown] = useState(3);
  const [processedWords, setProcessedWords] = useState([]);

  useEffect(() => setWords(phrase.split(/\s/)), [phrase]);
  useEffect(() => wotd && !urlPhrase && setPhrase(wotd.title), [wotd, urlPhrase]);
  useEffect(() => setProcessedWords(words.map(word => word.split(new RegExp(`(?<=[${vowels.join()}])`, "gu")))), [
    words
  ]);

  useEffect(() => {
    if (urlPhrase) {
      setPhrase(urlPhrase);
    }

    fetch("https://cors-anywhere.herokuapp.com/https://kupu.maori.nz/feed.xml", {
      headers: new Headers({ Accept: "text/xml" })
    })
      .then(resp => resp.text())
      .then(rss => new DOMParser().parseFromString(rss, "text/xml"))
      .then(doc => doc.querySelector("item"))
      .then(node => ({
        title: node.querySelector("title").textContent,
        description: simplifyWotdDescription(node.querySelector("description")),
        link: node.querySelector("link").textContent
      }))
      .then(wotd => (wotd ? setWotd(wotd) : null));
  }, [urlPhrase]);

  const addMacron = macron => {
    const input = document.getElementById("word");
    input.focus();
    input.value += macron;
    document.execCommand("selectAll", false, null);
    document.getSelection().collapseToEnd();

    setPhrase(input.textContent.trim());
  };

  const standardDuration = phrase.length / CHARACTERS_SPOKEN_PER_SECOND;

  return (
    <div className="app">
      <section id="word-entry">
        <div className="word-entry__instructions">
          Type in a Māori word or phrase{phrase === initialPhrase && !urlPhrase ? ", for example:" : ""}
        </div>
        <TextareaAutosize
          id="word"
          onChange={e => setPhrase(e.currentTarget.value.trim())}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          value={phrase}
          className="word-entry__word"
        />
        <div className="macron-options">
          <span className="word-entry__instructions">Quick select tohutō (macrons):</span>
          {macrons.map(m => (
            <Button key={`macron-${m}`} className="mdc-button--outline" outlined onClick={() => addMacron(m)}>
              {m}
            </Button>
          ))}
        </div>

        {wotd && wotd.title === phrase && (
          <div className="word-entry__instructions">
            {wotd && (
              <strong>
                <em>{wotd.description}</em>
              </strong>
            )}
            <br />
            This example word is from <a href="https://kupu.maori.nz">Kupu Te Rā</a>, the Māori language word of the day
            service.
          </div>
        )}

        <Button className="section-button" raised href="#vowel-pronounciation">
          Start
        </Button>
      </section>

      <section id="vowel-pronounciation">
        <div className="word-entry__instructions">Start by just pronouncing the vowels ({vowels.join(", ")}).</div>
        <ol>
          {processedWords.map((word, index) => (
            <React.Fragment key={`fragment-vowel-pronounciation-${word}`}>
              <Word key={`vowel-pronounciation__word-${word}-${index}`} word={word} />
              {index !== words.length - 1 && <WordDivider />}
            </React.Fragment>
          ))}
        </ol>
        <div className="word-entry__instructions">
          <br />
          Tap on a vowel to hear the sound being pronounced.
        </div>
        <Button className="section-button" raised href="#segment-pronounciation">
          ✓ Got it
        </Button>
      </section>

      <section id="segment-pronounciation">
        <div className="word-entry__instructions">Now add in the rest of each word section</div>
        <ol>
          {words.map((word, index) => (
            <React.Fragment key={`fragment-segment-pronounciation-${word}`}>
              <Word
                key={`segment-pronounciation__word-${word}-${index}`}
                word={[word]}
                define={true}
                decorate={false}
              />
              {index !== words.length - 1 && <WordDivider />}
            </React.Fragment>
          ))}
        </ol>

        <div className="word-entry__instructions">
          <br />
          Tap on <span style={{ textDecoration: "underline" }}>underlined</span> words to hear an example of the word
          being pronounced.
        </div>

        <Button className="section-button" raised href="#entire-pronounciation">
          Have a go at the whole thing
        </Button>
      </section>

      <section id="entire-pronounciation">
        <div style={{ display: "block", textAlign: "center" }}>
          <div className="word-entry__instructions">
            Finally, say the rest of the word. Start slowly, and gradually speed up to normal conversational pace.
            <br />
          </div>

          <PhraseSpeakalong duration={standardDuration} setSpeed={setSlowdown} phrase={phrase} slowdown={slowdown} />

          <Button className="section-button" raised href="#word-entry">
            {slowdown === 0 && "Ka pai! "} Start again?
          </Button>
        </div>

        <small className="attribution word-entry__instructions">
          Word definition audio from{" "}
          <a href="https://maoridictionary.co.nz/">Te Aka Māori-English, English-Māori Dictionary</a>.
          <br />
          Created by <a href="https://joshmcarthur.com">Josh McArthur</a>, Pōneke (Wellington), NZ.
        </small>
      </section>
    </div>
  );
}

export default App;
