import React from "react";
import { vowelAudio, domPlay as playGuidance } from "./audio";

export default ({ character }) => {
  return (
    <span
      role="button"
      tabIndex={0}
      className="character character--vowel with-audio"
      title={character}
      onClick={playGuidance}
      onKeyPress={playGuidance}
      data-audio={vowelAudio[character]}
    >
      {character}
    </span>
  );
};
