import React, { useState } from "react";
import Button from "@material/react-button";
import AudioRecorder from "react-audio-recorder";

export default ({ phrase, slowdown, duration, setSpeed }) => {
  const animationDuration = `${slowdown > 0 ? slowdown * duration : duration}s`;
  const [phraseStyle, setPhraseStyle] = useState({ animationDuration });
  let slowdownButton = null;
  let speedupButton = null;

  const doReset = () => {
    document.querySelector(".phrase.spread").classList.remove("animate-start");
    setPhraseStyle({ animation: "none" });
    setTimeout(() => setPhraseStyle({ animation: null, animationDuration }), 100);
    return true;
  };

  let recorder;

  slowdownButton = (
    <Button
      className="mdc-button--outline"
      outlined
      onClick={() => {
        setSpeed(slowdown + 1);
        doReset();
      }}
    >
      Slow down
    </Button>
  );

  speedupButton = (
    <Button
      className="mdc-button--outline"
      outlined
      disabled={!slowdown >= 1}
      onClick={() => {
        setSpeed(slowdown - 1);
        doReset();
      }}
    >
      Speed up
    </Button>
  );

  return (
    <>
      <span
        onAnimationEnd={() => recorder && recorder.state.isRecording && recorder.stopRecording()}
        style={phraseStyle}
        className={`phrase spread spread--${slowdown}`}
      >
        {phrase}
      </span>
      <div className="word-entry__instructions">
        <em>^ {slowdown > 0 ? `${slowdown}x slower than` : ""} conversational pace</em>
      </div>
      <br />
      <br />
      <br />
      <div className="word-entry__instructions">
        Hover or tap on the word to show the pace to match. Use the 'speed up' or 'slow down' buttons to adjust the
        pace.
      </div>

      {slowdownButton}
      <Button className="mdc-button--outline" outlined onClick={doReset}>
        Reset
      </Button>
      <AudioRecorder
        ref={el => (recorder = el)}
        onRecordStart={() => doReset() && document.querySelector(".phrase.spread").classList.add("animate-start")}
      />
      {speedupButton}

      <div className="word-entry__instructions">
        While training, you can use the 'Record' button to record your own voice, and play back the recording when you
        finish the word.
      </div>
      <br />
      <br />
    </>
  );
};
